<template>
  <div class="px-16 py-14 h-full bg-cream">
    <div class="flex items-center mb-3">
      <div class="flex">
        <div class="bg-secondary-main rounded-full p-4 pb-3.5">
          <CheckIcon color="white" class="flex items-center justify-center" />
        </div>
      </div>
      <div class="border-t border-secondary-main w-full mx-4"></div>
      <div class="flex">
        <div class="bg-transparent border-2 border-secondary-main rounded-full p-4 pb-3.5">
          <CheckIcon color="#FF7F51" class="flex items-center justify-center" />
        </div>
      </div>
      <div class="border-t border-sand w-full mx-4"></div>
      <div class="flex">
        <div class="bg-transparent border-2 border-secondary-main rounded-full p-4 pb-3.5">
          <CheckIcon color="#FF7F51" class="flex items-center justify-center" />
        </div>
      </div>
    </div>
    <div class="flex justify-between">
      <p class="label-xs-500">Find Pro</p>
      <p class="label-xs-500">Add Credit Card</p>
      <p class="label-xs-500">Start Job</p>
    </div>
    <div class="flex flex-col items-center my-10">
      <h1 class="heading-l-700">You're almost there.</h1>
      <p class="body-l-400 text-center">We just need your credit card details for future payments.</p>
      <p class="body-l-400 text-center">Important: No payment is due today, payments are only processed after the work has been completed by the Pro.</p>
    </div>

    <AddCard @saved="toggleHireProModal" :withCancelButton="false" saveBtnClasses="w-full" saveBtnText="Add Credit Card Details" />

    <GuaranteeMessage class="mt-12" />
    <AlertDialog
      ref="alertDialog"
      :confirm="`Yes, hire ${bid.pro.first_name}`"
      @confirm="hirePro($event)"
    >
      <template #title>
        Are you sure you want to hire {{ bid.pro.full_name }}? We will
        notify the other pros that this job is no longer open for bids
      </template>
    </AlertDialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import CheckIcon from "@/components/shared/svg/CheckIcon";
import AddCard from '@/components/shared/AddCard';
import GuaranteeMessage from '@/components/shared/GuaranteeMessage';
import AlertDialog from "@/components/shared/AlertDialog";

export default {
  name: 'CreditCardIncompleteModal',
  components: { CheckIcon, AddCard, GuaranteeMessage, AlertDialog },
  
  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      job: state => state.jobs.current,
      bid: state => state.bids.current,
    }),
  },

  methods: {
    ...mapActions({
      fetchJob: 'jobs/fetchJob',
      acceptBid: "bids/accept",
    }),

    ...mapMutations({
      hideModal: 'general/hideModal',
    }),

    toggleHireProModal() {
      this.$refs.alertDialog.$refs.modal.toggle();
      this.hideModal();
    },

    hirePro(callback) {
      this.acceptBid({ bidId: this.bid.id }).then((response) => {
        if (response) {
          callback.call();
          this.$router.push("/");
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  @apply py-3 px-10 rounded-lg text-white transition-all duration-300 border border-transparent flex justify-center items-center outline-none;
}
  
</style>